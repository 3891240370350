<template>
  <div>
    <report-list-add-new
      :is-add-new-report-sidebar-active.sync="isAddNewReportSidebarActive"
      @refetch-data="refetchData"
    />

    <report-list-edit
      ref="editReportComponent"
      :is-edit-report-sidebar-active.sync="isEditReportSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="4"
            class="d-flex align-items-left justify-content-end"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="t('Search...')"
            />
          </b-col>

          <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <b-button
                variant="primary"
                @click="isAddNewReportSidebarActive = true"
              >
              <span class="text-nowrap">{{ $t('Add Report') }}</span>
            </b-button>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refReportListTable"
        class="position-relative"
        :items="fetchReports"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        show-empty
        :empty-text="$t('No matching records found')"
      >

        <template #head(description)="data">
          <span class="text-secondary" >{{ t(data.label) }}</span>
        </template>

        <template #head(lifecycle)="data">
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <template #head(period_text)="data">
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <template #head(cdate)="data"> <!--ILL: 170322 se cambia a le, conforme esta en archivo usereportlist.js-->
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <template #head(actions)="data">
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <!-- Column: User -->
        <template #cell(description)="data">
          <b-media vertical-align="center">
            <b-link :id="`report-row-${data.item.uuid}-name`" class="font-weight-bold d-block text-nowrap">
              
              {{ data.item.description.length > 100 ? data.item.description.substr(0,97) + '. . .' : data.item.description }}<!--ILL: 231122 se evalúa el tamaño de la longitud de la descripción del reporto para solo presentar 60 caracteres y no se descuadre el grid-->              
            </b-link>
            <b-tooltip v-if="data.item.description.length > 100"
              class="cursor-pointer"
              :target="`report-row-${data.item.uuid}-name`"
            >
              <span style="font-size: 7.5pt;">{{ data.item.description }}</span>
            </b-tooltip>
            <!--<div v-if="data.item.sku !== ''" class="text-black"><b>ID:</b> {{ data.item.id }} <b>SKU:</b> {{ data.item.sku }}</div>
            <div v-if="data.item.sku === ''" class="text-black"><b>ID:</b> {{ data.item.id }}</div>-->
            <!-- <small class="text-black"><b>ID:</b> {{ data.item.uuid }} <b>Elementos:</b> {{ data.item.uuid }}</small> -->
          </b-media>
        </template>

        <!-- Column: Sat Id -->
        <template #cell(lifecycle)="data">
          <b-media vertical-align="center">
            <b-badge
              v-if="data.item.lifecycle === 'done'"
              pill
              variant="light-primary"
            >
              {{ $t(`ReportStatus.${data.item.lifecycle}`) }}
            </b-badge>
            <b-badge
              v-if="data.item.lifecycle === 'generating'"
              pill
              variant="light-warning"
            >
              {{ $t(`ReportStatus.${data.item.lifecycle}`) }}
            </b-badge>
            <b-badge
              v-if="data.item.lifecycle === 'failed'"
              pill
              variant="light-danger"
            >
              {{ $t(`ReportStatus.${data.item.lifecycle}`) }}
            </b-badge>
          </b-media>
        </template>

        <!-- Column: TaxId -->
        <template #cell(period_text)="data">
          <div class="text-nowrap">
            <template>
              {{ data.item.period_text}}
            </template>
          </div>
        </template>

        <!-- Column: Ultima Factura donde se Utilizo -->
        <template #cell(cdate)="data">
          <div class="text-nowrap">
            <template >
              <span class="align-text-top text-capitalize">{{ Intl.DateTimeFormat('es-MX', { day:'2-digit', month:'2-digit', year:'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(data.item.cdate)) }}</span>
            </template>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row>
            <b-col cols="2" v-if="data.item.lifecycle === 'done'">
              <feather-icon
                :id="`contact-row-${data.item.uuid}-delete-icon`"
                icon="Trash2Icon"
                class="cursor-pointer"
                size="16"
                @click="deleteReport(data.item.uuid)"
              />
              <b-tooltip
                :title="t('delete')"
                class="cursor-pointer"
                :target="`contact-row-${data.item.uuid}-delete-icon`"
              />
            </b-col>
            <b-col cols="2" v-if="data.item.lifecycle === 'done'">
              <feather-icon
                :id="`contact-row-${data.item.uuid}-get-icon`"
                icon="ArrowDownCircleIcon"
                class="cursor-pointer"
                size="16"
                @click="getReport(data.item.uuid, data.item.description)"
              />
              <b-tooltip
                :title="t('DownloadReport')"
                class="cursor-pointer"
                :target="`contact-row-${data.item.uuid}-get-icon`"
              />
            </b-col>
          </b-row>
        </template>

  

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ t('Showing') }} {{ dataMeta.from }} {{ t('to') }} {{ dataMeta.to }} {{ t('of') }} {{ dataMeta.of }} {{ t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalReports"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, getCurrentInstance } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import useReportList from './useReportList'
import reportStoreModule from './reportStoreModule'
import ReportListAddNew from './ReportListAddNew.vue'
import ReportListEdit from './ReportListEdit.vue'

export default {
  components: {
    ReportListAddNew,
    ReportListEdit,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  mounted() {
    this.$root.$on('session-refresh',(message) => {
      this.session = JSON.parse(localStorage.getItem('session'))
      if (this.$store.hasModule(this.USER_APP_STORE_MODULE_NAME))
        this.refetchData()
    })
  },
  methods: {
    addCfdiFromReport (uuid) {
      this.$router.replace({ name: 'fg-cfdi-add-from-report', params: { puuid: uuid }})
        .then(() => {
          
        })
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'fg-report'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, reportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewReportSidebarActive = ref(false)
    const isEditReportSidebarActive = ref(false)
    /** lsandoval: 13/01/2022 We import the i18n utilities */
    const { t } = useI18nUtils()

    const vm = getCurrentInstance().proxy

    const {
      fetchReports,
      tableColumns,
      perPage,
      currentPage,
      totalReports,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refReportListTable,
      refetchData,
      deleteReport,
      getReport,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveReportTypeVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useReportList()

    const fetchReport = (uuid) => {
      let v = vm.$refs['editReportComponent']
      v.fetchReport(uuid)
    }

    return {

      // Sidebar
      isAddNewReportSidebarActive,
      isEditReportSidebarActive,

      fetchReports,
      fetchReport,
      tableColumns,
      perPage,
      currentPage,
      totalReports,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refReportListTable,
      refetchData,
      deleteReport,
      getReport,
      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveReportTypeVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      // i18n
      t,

      USER_APP_STORE_MODULE_NAME
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
./reportStoreModule./useReportList