import store from "@/store";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useReportList() {
  // Use toast
   // Access translation function and locale
  
  const toast = useToast();

  const addReport = (context, cb, t) => {
    const [startYear, startMonth] = context.startDate.split("-");
    const [endYear, endMonth] = context.endDate.split("-");
    const request = {
      period: {
        from: {
          year: startYear,
          month: startMonth,
        },
        to: {
          year: endYear,
          month: endMonth,
        },
      },
      description: context.description,
      filters: {
        sat_folio: context.satFolio,
        type: {
          key: context.type.ksat,
          value: context.type.v,
        },
        receiver_taxid: context.receiverTaxid,
        serie: context.serie,
        internal_folio: context.internalFolio,
      },
    };
    store
      .dispatch("fg-report/addReport", request)
      .then(() => {
        cb();
        const v0 =t("SuccessReportCreationTitle");
        //const v0 =  t("buy instead of")

        const v1 = t("SuccessReportCreationText");

        toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: v0,
            icon: "CheckIcon",
            variant: "success",
            text: v1,
          },
        });
      })
      .catch((error) => {
        let v0 = t("FailedReportCreationTitle");
        let v1 = t("FailedReportCreationText");
        let variant = "danger";
        if (error?.response?.data?.message === "cfdis_not_found") {
          v0 = t("WarningReportCreationTitle");
          v1 = t("WarningReportCreationText");
          variant = "warning";
        }
        if (error?.response?.data?.message === "report_found") {
          cb();
          return
        }
        console.log(v0, v1, variant);
        toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: v0,
            icon: "AlertTriangleIcon",
            variant,
            text: v1,
          },
        });
      });
  };

  return {
    addReport,
  };
}
