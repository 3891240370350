import { ref, watch, computed, getCurrentInstance } from "@vue/composition-api";
import store from "@/store";
import { formatDateMX, kFormatter } from "@core/utils/filter";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useReportList() {
  // Use toast
  const toast = useToast();

  const refReportListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: 'description',
      label: 'Description',
      //sortable: true
    },
    {
      key: 'lifecycle',
      label: 'Lifecycle',
      //sortable: true
    },
    {
      key: 'period_text',
      label: 'Period',
      //sortable: true
    },
    {
      key: 'cdate',
      label: 'create date',
      //sortable: true,
    },
    {
      key: 'actions',
      label: 'Actions',
    },
  ];
  const perPage = ref(10);
  const totalReports = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refReportListTable.value
      ? refReportListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReports.value,
    };
  });

  const refetchData = () => {
    refReportListTable.value.refresh();
  };

  watch([searchQuery], (v) => {
    if (searchQuery.value !== "" && searchQuery.value.length >= 4) {
      refetchData();
    } else if (searchQuery.value === "") {
      refetchData();
    }
  });

  watch([currentPage, perPage], () => {
    refetchData();
  });

  const months = {
    "01": "Enenero",
    "02": "Febrero",
    "03": "Marzo",
    "04": "Abril",
    "05": "Mayo",
    "06": "Junio",
    "07": "Julio",
    "08": "Agosto",
    "09": "Septiembre",
    "10": "Octubre",
    "11": "Noviembre",
    "12": "Diciembre"
  }

  const fetchReports = (ctx, callback) => {
    store
      .dispatch("fg-report/listReport", {
        items_per_page: perPage.value,
        page: currentPage.value,
        filter: searchQuery.value,
      })
      .then((response) => {
        const { items, total_items } = response.data;
        items.forEach(e => {
          e.period_text = `${e.period.from.month}/${e.period.from.year} - ${e.period.to.month}/${e.period.to.year}`
        })
        callback(items);
        totalReports.value = total_items;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error al obtener la lista de reportos",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const deleteReport = (uuid) => {
    console.group('fg.views.reportList deleteReport')
    console.log('uuid', uuid)
    console.groupEnd();
    const request = {
      uuid
    }
    store.dispatch('fg-report/deleteReport', request)
      .then((res) => {
        refetchData()
      })
  }


  const getReport = (uuid, description) => {
    store
      .dispatch("fg-report/getReport", {
        uuid,
      })
      .then((response) => {
        const { url } = response.data.document;
        fetch(url)
          .then(response => response.blob()) // Convert the response to a blob
          .then(blob => {
            // Create a downloadable URL from the blob
            const blobUrl = URL.createObjectURL(blob);

            // Sanitize the description to remove special characters
            const sanitizedDescription = description.replace(/[^a-zA-Z0-9_]/g, "_");

            // Create element <a> for download
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `${sanitizedDescription}.csv`; // Set the custom filename

            // Simulate a click on the element <a>
            document.body.appendChild(link);
            link.click();

            // Remove the link element after the download
            document.body.removeChild(link);

            // Release the blob URL after download
            URL.revokeObjectURL(blobUrl);
          })
          .catch(error => {
            console.error('Error fetching the file:', error);
          });
        //  // create element <a> for download csv
        //   const link = document.createElement('a');
        //   link.href = url;
        //   console.log("" + description.replaceAll(" ", "_") + ".csv");
        //   link.download = "" + description.replaceAll(" ", "_") + ".csv";

        //   // Simulate a click on the element <a>
        //   document.body.appendChild(link);
        //   link.click();
        //   document.body.removeChild(link);
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error al obtener el documento",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };



  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveReportTypeVariant = (type) => {
    if (type === "p") return "primary";
    if (type === "s") return "secondary";
    return "primary";
  };

  return {
    fetchReports,
    tableColumns,
    perPage,
    currentPage,
    totalReports,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReportListTable,

    resolveReportTypeVariant,
    deleteReport,
    refetchData,
    getReport,
    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
