<template>
  <b-sidebar
    id="add-new-report-sidebar"
    :visible="isAddNewReportSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="600px"
    no-close-on-esc
    no-close-on-backdrop
    no-close-on-route-change
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-report-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ t('AddNewReport') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit, invalid  }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <b-col cols="6">
              <!-- Report ID -->
              <validation-provider
                #default="validationContext"
                name="Id"
                rules="date"
              >
                <b-form-group
                  :label="t('DatePeriodStart')"
                  label-for="report-start-date"
                  label-size="sm"
                >
                  
                    <b-form-input
                      id="report-start-date"
                      type="month"
                      v-model="reportData.startDate"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      :placeholder="t('idholder')"
                      @change="updateDescription"
                    />
                    <b-form-invalid-feedback>
                {{ t(validationContext.errors[0]) }}
              </b-form-invalid-feedback>
                  
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <!-- Report SKU -->
              <validation-provider
                #default="validationContext"
                name="SKU"
                rules="date"              
              >
                <b-form-group
                  :label="t('DatePeriodEnd')"
                  label-for="report-end-date"
                  label-size="sm"
                >
                  <b-form-input
                    id="report-end-date"
                    type="month"
                    :max="maxDate"
                    v-model="reportData.endDate"
                    autofocus
                    :state="getValidationState(validationContext)"
                    :formatter="fmtUppercase"
                    trim
                    :placeholder="t('DatePeriodEnd')"
                    @change="updateDescription"
                  />
                  <b-form-invalid-feedback>
                {{ t(validationContext.errors[0]) }}
              </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Receipt Type -->
          <validation-provider
            #default="validationContext"
            name="ReceiptType"
          >
            <b-form-group
              :label="t('ReceiptType')"
              label-for="satunit"
              label-size="sm"
            >
              <v-select
                id="satunit"
                v-model="reportData.type"
                text-field="v"
                label="v"
                :options="receiptType"
                @input="updateDescription"
              >
                <template #selected-option="option">
                  <div v-if="option && option.v !== ''" style="display: flex; align-items: baseline">
                    <strong>{{ option.ksat }}</strong>
                    <em style="margin-left: 0.5rem">: {{ option.v }}</em>
                  </div>
                  <div v-else>
                  </div>
                </template>
                <template v-slot:option="option">
                  {{ option.ksat }} : {{ option.v }}
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Descripción del reporto -->
          <validation-provider
            #default="validationContext"
            name="Description"
            rules="description"
          >
            <b-form-group
              :label="t('Description')"
              label-for="desc"
              v-b-tooltip.hover.top="t('rdescrip')"
              :invalid-feedback="t('rdescripf')"
              label-size="sm"
            >
            <!-- ILL: 230623 se agrega validación para que no se pueda capturar un pipe en la descripción del reporto, ya que el mismo no es permitido conforme a XSD (validateNoPipe)-->
              <b-form-input
                id="desc"
                v-model="reportData.description"
                :state="getValidationState(validationContext) && validateNoPipe"
                :formatter="fmtStrDesc"
                trim
              />
              <b-form-invalid-feedback>
                {{ t(validationContext.errors[0]) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="divider my-2">
            <div class="divider-text">
              {{ t('OptionalFields') }}              
            </div>
          </div>          
            <b-row>
              <b-col cols="4">
              <validation-provider
              #default="validationContext"
              name="ReceiverTaxid"
              rules="taxid"
              >
                <b-form-group
                  :label="t('ReceiverTaxid')"
                  label-for="desc"
                  label-size="sm"
                >
                <!-- ILL: 230623 se agrega validación para que no se pueda capturar un pipe en la descripción del reporto, ya que el mismo no es permitido conforme a XSD (validateNoPipe)-->
                  <b-form-input
                    id="desc"
                    :state="getValidationState(validationContext)"
                    v-model="reportData.receiverTaxid"                    
                    :formatter="fmtUppercase13"
                    trim
                  />
                  <b-form-invalid-feedback>
                {{ t(validationContext.errors[0]) }}
              </b-form-invalid-feedback>
                </b-form-group>
                
              </validation-provider>
              </b-col>
              <b-col cols="8">
                <b-form-group
                  :label="t('SatFolio')"
                  label-for="desc"
                  :invalid-feedback="t('descreq')"
                  label-size="sm"
                >
                <!-- ILL: 230623 se agrega validación para que no se pueda capturar un pipe en la descripción del reporto, ya que el mismo no es permitido conforme a XSD (validateNoPipe)-->
                  <b-form-input
                    id="desc"
                    v-model="reportData.satFolio"
                    :formatter="fmt1000num"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="4">
                <b-form-group
                  :label="t('Branch Prefix')"
                  label-for="desc"
                  :invalid-feedback="t('descreq')"
                  label-size="sm"
                >
                <!-- ILL: 230623 se agrega validación para que no se pueda capturar un pipe en la descripción del reporto, ya que el mismo no es permitido conforme a XSD (validateNoPipe)-->
                  <b-form-input
                    id="desc"
                    v-model="reportData.serie"
              
                    :formatter="fmtUppercase3"
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col cols="8">
                <b-form-group
                  :label="t('InternalFolio')"
                  label-for="desc"
                  :invalid-feedback="t('descreq')"
                  label-size="sm"
                >
                <!-- ILL: 230623 se agrega validación para que no se pueda capturar un pipe en la descripción del reporto, ya que el mismo no es permitido conforme a XSD (validateNoPipe)-->
                  <b-form-input
                    id="desc"
                    v-model="reportData.internalFolio"
                    :formatter="fmt1000num"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <div class="divider my-2">
            
          </div> 

          <b-row>
              <b-col >
                <b-button
                type="submit"
                block
                variant="primary"
                :disabled="invalid || !validateDates || !validateType"
              >
                <span class="">{{ $t('Add Report') }}</span>
                </b-button>
              </b-col>
            </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BRow, BCol, BFormCheckbox, VBTooltip, BInputGroup,BInputGroupPrepend,
} from 'bootstrap-vue'
import { getCurrentInstance } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver, extend  } from 'vee-validate'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import receiptType from '@/@catalogs/sat/c_tipocomprobante'
import sattaxIvaTranslateList from '@/@catalogs/sat/c_iva_tra'
import sattaxIvaRetentionList from '@/@catalogs/sat/c_iva_ret'
import sattaxIepsTranslateList from '@/@catalogs/sat/c_ieps_tra'
import sattaxIepsRetentionList from '@/@catalogs/sat/c_ieps_ret'
import sattaxIsrRetentionList from '@/@catalogs/sat/c_isr_ret'
import sateduSchoolLevelList from '@/@catalogs/sat/c_niveledu'
/** fer@arkeline.dev 21/03/2023 Report Currency Feature */
import useReportListAddNew from './useReportListAddNew'
import currencyList from '@/@catalogs/sat/c_moneda'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t } from '@/@core/libs/i18n/utils';
const dateRegEx = /^[\d]{4}-[\d]{2}$/;
const taxIdRegEx = /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/
extend('date',{
  validate: (value) => {
    return {
      valid: dateRegEx.test(value),
      required: true
    }
  },
  computesRequired: true,
  message: "DateRuleMessage"
})

extend('description',{
  validate: (value) => {
    return {
      valid: value?.length>10,
      required: true
    }
  },
  computesRequired: true,
  message: "DescriptionRuleMessage"
})

extend('taxid',{
  validate: (value) => {
    return taxIdRegEx.test(value)
  },
  message: "TaxIdRuleMessage"
})
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    vSelect,
    BInputGroup,
    BInputGroupPrepend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  model: {
    prop: 'isAddNewReportSidebarActive',
    event: 'update:is-add-new-report-sidebar-active',
  },
  props: {
    isAddNewReportSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isCreationEnabled: {
      get() {
      
        return this.reportData.id !== '' && 
               this.reportData.desc 
      }
    },
    validateNoPipe(){
      const regEx = /^[^|]{1,1000}$/
      const isValid = regEx.test(this.reportData.desc);
      this.descripValid = isValid
      if(isValid){        
      }else{
        this.reportData.desc = ''
      }

      return isValid;
    },
    validateDates(){
      return new Date(this.reportData.startDate) <= new Date(this.reportData.endDate)
    }   
    ,
    validateType(){
      return this.reportData.type.k && this.reportData.type.v
    }  
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      receiptType,
      sattaxIvaTranslateList,
      sattaxIvaRetentionList,
      sattaxIsrRetentionList,
      sattaxIepsTranslateList,
      sattaxIepsRetentionList,
      sateduSchoolLevelList,

      /** fer@arkeline.dev 21/03/2023 Report Currency Feature */
      currencyList,
      descripValid: false,
      satclassList: [],
      session: null,
      supportRealstate: false,
      supportSchoolPrivate: false,
      sectionRealstate: false,
      sectionSchoolPrivate: false
    }
  },
  mounted () {
      this.session = JSON.parse(localStorage.getItem('session'))
      let taxpayerArray = Array.isArray(this.session.business.taxpayer) ? this.session.business.taxpayer : [this.session.business.taxpayer]
      taxpayerArray.forEach(e => {
        /** We need to find a better way to resolve this */
        if(e.k === '606' || e.k === '601' || e.k === '603' || e.k === '626') { // Arrendamiento, Regimen General de Personas Morales, Personas morales fines no lucrativos, Resico 
          this.supportRealstate = true
        }
  //ILL: 180822 se agrega rif (621) para colegiaturas
        if(e.k === '601' || e.k === '603' || e.k === '626' || e.k === '612' || e.k === '621') { // Regimen General de Personas Morales, Personas morales fines no lucrativos, Resico, Personas Fisicas con Actividad Empresarial, RIF
          this.supportSchoolPrivate = true
        }
      })
  },  
  setup(props, { emit }) {
    const blankReportData = {
      startDate: '', // start date
      endDate: '', // end date
      type: {
        k: "",
        v: "",
      }, // Tipo P/S
      description: '', // Configuracion, si no existe es compatible en todos lados
      satFolio:'',
      receiverTaxid:'',
      serie:'',
      internalFolio:'',
    }

    const reportData = ref(JSON.parse(JSON.stringify(blankReportData)))
    const resetreportData = () => {
      reportData.value = JSON.parse(JSON.stringify(blankReportData))
    }

    const {
      addReport
    } = useReportListAddNew()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetreportData)

    /** lsandoval: 26/01/2022 We import the i18n utilities */
    const { t } = useI18nUtils()
    const { proxy } = getCurrentInstance();  // Get the Vue instance
    const boundT = proxy.$t.bind(proxy);  

    const fireEvents = () => {
      emit('refetch-data')
      emit('update:is-add-new-report-sidebar-active', false)
    }
  

    const onSubmit = function() {
      addReport(reportData.value, fireEvents, boundT)
    }
    const today = new Date();
    const mm = today.getMonth() + 1;
    const maxDate = `${today.getFullYear()}-${mm > 9 ? mm : `0${mm}`}`
    return {
      reportData,
      onSubmit,
      maxDate,
      refFormObserver,
      getValidationState,
      resetForm,

      t,
    }
  },
  methods: {
    fmtUppercase3(value) {
          let v = value.toUpperCase()
          v = v.length > 3 ? v.substr(0,3) : v
          return v
      },
      fmtUppercase13(value) {
          let v = value.toUpperCase()
          v = v.length > 13 ? v.substr(0,13) : v
          return v
      },       
    fmtUppercase(value) {
        let v = value.toUpperCase()
        return v
    },
    // ILL: 270122 para que la longitud del campo phone sea max = 10
    fmt10num(value) {        
        let v=value
        v = v.length > 10 ? v.substr(0,10) : v
        return v
    },
    // ILL: 231122 para que la longitud del campo descripción se max = 999
        fmt1000num(value) {        
        let v=value
        v = v.length > 999 ? v.substr(0,999) : v
        return v
    },  
      // ILL: 120423 para que la longitud del campo id sea max = 15
        fmt15num(value) {        
        let v = value.toUpperCase()
        v = v.length > 20 ? v.substr(0,20) : v
        return v
    },
    fmtStrDesc(value){
      return value.replace(/[^a-zA-Z0-9-_\s]/, '').slice(0,200)
    },
    updateDescription(){
      this.reportData.description = `Reporte de ${this.reportData.type.v} del ${this.reportData.startDate && this.reportData.startDate.split("-").reverse().join("-")} al ${this.reportData.endDate && this.reportData.endDate.split("-").reverse().join("-")}`
    },
    changePYS () {
      console.log('pys selection', this.reportData.sat_class)
      if(this.reportData.sat_class && this.reportData.sat_class.k !== '') {
        this.reportData.type = this.reportData.sat_class.t
      } else {
        this.reportData.type = ''
      }

      /** Realstate section */
      this.sectionRealstate = false
      this.reportData.extra.lease.flag = false
      this.reportData.extra.lease.id = ''
      /** School Private section */
      this.sectionSchoolPrivate = false
      this.reportData.extra.school.flag = false
      this.reportData.extra.school.auth = ''
      this.reportData.extra.school.level = ''
      
      if(this.reportData.sat_class && this.reportData.sat_class.c){
        switch(this.reportData.sat_class.c.b) {
          case 'fg.leasing.realstate':
            this.sectionRealstate = true 
            this.reportData.config = 'fg.leasing.realstate'
            this.reportData.extra.lease.flag = true
          break;
          case 'fg.school.private':
            this.sectionSchoolPrivate = true 
            this.reportData.config = 'fg.school.private'
            this.reportData.extra.school.flag = true
          break;
        }
        this.reportData.sat_unit = this.receiptType.find(o => o.k === this.reportData.sat_class.c.u[0])
        /** fer@arkeline.io We need to check if t is an Object or an Array */
        if(Array.isArray(this.reportData.sat_class.c.t)) {
          if(this.reportData.sat_class.c.t.find(item => item === 'iva_t')) { 
            this.reportData.taxes.iva_ta = true
          }
        } else if(this.reportData.sat_class.c.t.iva_t) { 
          this.reportData.taxes.iva_ta = true
          this.reportData.taxes.iva_tr = { k: this.reportData.sat_class.c.t.iva_t.t, v: this.reportData.sat_class.c.t.iva_t.t, c_impuesto_k: '002' }
        }
      } else {
        this.reportData.taxes.iva_ta = false
        this.reportData.taxes.iva_ra = false
        this.reportData.taxes.isr_ra = false
        this.reportData.taxes.ieps_ta = false
      }

    },
    searchPYS (value, loading) {
      // Hay que validar antes el envio
      if(value && value.length < 3)
        return 

      loading(true)
      this.$store.dispatch('fg-report/searchPYS', value )
        .then((response) => {
          const pysList = response.data.list
          const pysListConcrete = []

          pysList.forEach(e => {
            if(e.c) {
              if(this.supportRealstate && e.c.b === 'fg.leasing.realstate'){
                pysListConcrete.push(e)
              }

              if(this.supportSchoolPrivate && e.c.b === 'fg.school.private'){
                pysListConcrete.push(e)
              }
            } else pysListConcrete.push(e)
          })

          this.satclassList = pysListConcrete
          loading(false)
        })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
